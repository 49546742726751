<script>
  import { score } from "./store.js";
  export let question;
  export let nextQuestion;

  let w;
  let isCorrect;
  let isAnswered = false;

  let answers = question.incorrect_answers.map((answer) => {
    return {
      answer,
      correct: false,
    };
  });

  let allAnswers = [
    ...answers,
    {
      answer: question.correct_answer,
      correct: true,
    },
  ];

  shuffle(allAnswers);

  function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
  }

  function checkQuestion(correct) {
    isCorrect = correct;
    isAnswered = true;
    if (isCorrect) {
      score.update((val) => val + 1);
    }
  }
</script>

<h3>
  {@html question.question}
</h3>

{#each allAnswers as answer}
  <button
    bind:clientWidth={w}
    disabled={isAnswered}
    on:click={() => checkQuestion(answer.correct)}
  >
    {@html answer.answer}
  </button>
{/each}

{#if isAnswered}
  <h4 class={isCorrect ? "correct" : "wrong"}>
    {#if isCorrect}
      You got it!
    {:else}
      Nope, that's wrong.
    {/if}
  </h4>
  <div>
    <button on:click={nextQuestion}>Next</button>
  </div>
{/if}

<style>
  .wrong {
    color: rgb(161, 1, 1);
  }
  .correct {
    color: rgb(1, 107, 1);
  }
</style>
