<script>
  import { fly, fade } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
</script>

<div class="modal-bg" transition:fade>
  <div class="modal" transition:fly={{ y: -100 }}>
    <button
      on:click={() => {
        dispatch("close");
      }}>Close</button
    >
    <slot />
  </div>
</div>

<style>
  .modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(0.8, 0, 0);
  }
  .modal {
    background: rgb(141, 140, 138);
    color: rgb(34, 27, 15);
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    max-width: 240px;
    margin: auto;
  }
</style>
