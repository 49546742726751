<script>
  import { fly } from "svelte/transition";

  import Question from "./Question.svelte";
  import Modal from "./Modal.svelte";
  import { score } from "./store.js";

  let activeQuestion = 1;
  let quiz = getQuiz();
  let isModalOpen = false;

  async function getQuiz() {
    const res = await fetch("https://cfquiz.cloudsucked.com/api");
    const quiz = await res.json();
    return quiz;
  }

  function nextQuestion() {
    activeQuestion = activeQuestion + 1;
  }

  function resetQuiz() {
    score.set(0);
    activeQuestion = 1;
    quiz = getQuiz();
    isModalOpen = false;
  }

  // Reactive statement
  $: if ($score > 4) {
    isModalOpen = true;
  }

  $: questionNumber = activeQuestion;
</script>

<div>
  <button on:click={resetQuiz}>Reset Quiz</button>

  <h3>My Score: {$score}</h3>
  <h4>Question #{activeQuestion}</h4>

  {#await quiz}
    <div>loading...</div>
  {:then data}
    {#each data.results as question, index}
      {#if index + 1 === questionNumber}
        <div
          in:fly={{ x: 200, duration: 1000 }}
          out:fly={{ x: -200, duration: 1000 }}
          class="transition-wrapper"
        >
          <Question {nextQuestion} {question} />
        </div>
      {/if}
    {/each}
  {/await}
</div>

{#if isModalOpen}
  <Modal on:close={resetQuiz}>
    <h2>You won!</h2>
    <button on:click={resetQuiz}>Start Over</button>
  </Modal>
{/if}

<style>
  .transition-wrapper {
    position: absolute;
  }
</style>
